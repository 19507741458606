import api from './api';

const API_PREFIX = "api/patient";

const deletePatient = async (id) => {
    const response = await api.delete(`${API_PREFIX}/${id}`);
    return response;
};



const getSameDateTransferData = async (body) => {
    const response = await api.post(`${API_PREFIX}/get-same-date-transfer`, body);
    return response;
};

export {
    deletePatient,
    getSameDateTransferData
};
