import React, { useCallback, useEffect, useMemo, useState } from "react";
import Cleave from "cleave.js/react";
import Validation from "./validation/Validation";
import styles from "./AddPatient.module.scss";
import questionsStyles from "./Questions.module.scss";
import TimeKeeper from "react-timekeeper";
import { useRef } from "react";
import classNames from "classnames";
import HtmlTooltip from "../../../../shared/HtmlTooltip";
import { getAllQuestions } from "../../../../../services/api/question.api";
import _ from "lodash";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import QuestionLabel from "./QuestionLabel";
import AddPatientTextPopup from "./AddPatientTextPopup";


const TextQuestion = ({ question, allFields, setAllFieldsValue, checkError = false, handleClickExpand, handleAddHighlight }) => {

    const isExpand = useMemo(() => {
        // eslint-disable-next-line eqeqeq
        return question.accessor === "notes" || question?.defaultOrder == undefined;
    }, [question]);

    const isHighLightInfo = useMemo(() => {
        return allFields?.highlighter?.find(item => item.fieldName === question.accessor)?.color;
        // eslint-disable-next-line
    }, [allFields]);

    return (
        <div className={`m-b-20 inputWrpr`}>
            <QuestionLabel
                handleAddHighlight={handleAddHighlight}
                accessor={question.accessor}
                allFields={allFields}
            >
                <label
                    className={`ffmr fs12 ${checkError && !allFields[question.accessor] && question?.isRequired === true
                        ? "error"
                        : null
                        }`}
                >
                    {question?.order?.label || question.label}
                </label>
            </QuestionLabel>
            <TextField
                fullWidth
                value={allFields[question.accessor]}
                onChange={e => setAllFieldsValue(question.accessor, e.target.value)}
                variant="standard"
                autoComplete="off"
                InputProps={{
                    disableUnderline: true,
                    ...isExpand && {
                        endAdornment: <InputAdornment position="end">
                            <IconButton
                                sx={{ marginRight: "-2px" }}
                                onClick={() => handleClickExpand(allFields[question.accessor], question.accessor)}
                                edge="end"
                                size="small"
                                disableRipple={true}
                            >
                                <OpenInFullIcon sx={{ color: "#8f8f8f" }} />
                            </IconButton>
                        </InputAdornment>
                    }
                }}
                sx={{
                    "input": {
                        borderRadius: "4px",
                        border: "none !important",
                    },
                    '& .MuiInputBase-root': {
                        height: "40px",
                        borderRadius: "4px",
                        ...isHighLightInfo && { border: `1px solid ${isHighLightInfo} !important` },
                    },
                }}
            />
        </div>
    );
};

const DateQuestion = ({ question, allFields, setAllFieldsValue, checkError = false, handleAddHighlight }) => {
    const isHighLightInfo = useMemo(() => {
        return allFields?.highlighter?.find(item => item.fieldName === question.accessor)?.color;
        // eslint-disable-next-line
    }, [allFields]);

    return (
        <div className={`m-b-20 inputWrpr`}>
            <QuestionLabel
                handleAddHighlight={handleAddHighlight}
                accessor={question.accessor}
                allFields={allFields}
            >
                <label
                    className={`ffmr fs12 ${checkError && !allFields[question.accessor] && question?.isRequired === true
                        ? "error"
                        : null
                        }`}
                >
                    {question?.order?.label || question.label} <span style={{ fontSize: "0.8em", opacity: 0.7 }}>(MM/DD/YYYY)</span>
                </label>
            </QuestionLabel>

            <Cleave
                className={`ffml fs16`}
                value={allFields[question.accessor]}
                onChange={e => setAllFieldsValue(question.accessor, e.target.value)}
                options={{
                    date: true,
                    datePattern: ["m", "d", "Y"],
                }}
                style={{
                    ...(isHighLightInfo ? { border: `1px solid ${isHighLightInfo}` } : {})
                }}
            />
        </div>
    );
};
const ValidationQuestion = ({ question, allFields, setAllFieldsValue, checkError = false, handleAddHighlight, selectedProps }) => {    
    let value = allFields[question.accessor];

    if (typeof value === 'object' && !Array.isArray(value) && value !== null) {
        value = value ? value?._id : null;
    } else if (question.multiple) {
        value = value && value.length > 0 ? _.map(value, "_id") : [];
    }

    const isHighLightInfo = useMemo(() => {
        return allFields?.highlighter?.find(item => item.fieldName === question.accessor)?.color;
        // eslint-disable-next-line
    }, [allFields]);

    return (
        <div className={`m-b-20 inputWrpr`}>
            <QuestionLabel
                handleAddHighlight={handleAddHighlight}
                accessor={question.accessor}
                allFields={allFields}
            >
                <label
                    className={`ffmr fs12 ${checkError && !allFields[question.accessor] && question?.isRequired === true
                        ? "error"
                        : null
                        }`}
                >
                    {question?.order?.label || question.label}
                </label>
            </QuestionLabel>
            <Validation
                selectedItem={selectedProps?.selectedItem}
                isHighLightInfo={isHighLightInfo}
                type={question.validationType}
                validationBase={question.validationBase}
                value={value}
                multiple={question.multiple}
                onChange={val => {
                    if (question.multiple) {
                        const value = val.filter(item => item !== undefined)
                        if (value) {
                            setAllFieldsValue(
                                "dx",
                                value.length > 0 ? value.map(v => v?._id) : []
                            );
                        }
                    } else {
                        setAllFieldsValue(question.accessor, val);
                    }
                }}
            />
        </div>
    );
};

const ToggleQuestion = ({ question, allFields, setAllFieldsValue, checkError = false, isForceRequired = false, handleAddHighlight }) => {
    const isHighLightInfo = useMemo(() => {
        return allFields?.highlighter?.find(item => item.fieldName === question.accessor)?.color;
        // eslint-disable-next-line
    }, [allFields]);
    return (
        <div className={`m-b-20 inputWrpr`}>
            <QuestionLabel
                handleAddHighlight={handleAddHighlight}
                accessor={question.accessor}
                allFields={allFields}
            >
                <label
                    className={`ffmr fs12 ${checkError &&
                        (allFields[question.accessor] === undefined || allFields[question.accessor] == null || isForceRequired || (allFields?.type === "transfer" && allFields?.transferType === "hospitalTransfer")) &&
                        (question?.isRequired === true || isForceRequired)
                        ? "error"
                        : null
                        }`}
                >
                    {question?.order?.label || question.label}
                </label>
            </QuestionLabel>
            <div className={`df aic ffml fs14 ${styles.toggleWrpr}`}
                style={{
                    ...(isHighLightInfo && {
                        border: `1px solid ${isHighLightInfo}`,
                        borderRadius: "4px",
                    }),
                }}
            >
                {question.options.map(option => (
                    <div
                        className={`${styles.sec} ${allFields[question.accessor] === option.value ? styles.selected : ""
                            }`}
                        onClick={() => setAllFieldsValue(question.accessor, option.value)}
                    >
                        {option.label}
                    </div>
                ))}
            </div>
        </div>
    );
};

const TimeQuestion = ({ question, allFields, setAllFieldsValue, checkError = false, handleAddHighlight }) => {
    const [showTimeSelect, setShowTimeSelect] = useState(false);
    const [time, setTime] = useState(allFields[question.accessor] || undefined);
    const timePickerRef = useRef(null);

    const isHighLightInfo = useMemo(() => {
        return allFields?.highlighter?.find(item => item.fieldName === question.accessor)?.color;
        // eslint-disable-next-line
    }, [allFields]);

    useEffect(() => {
        const outsideClick = e => {
            showTimeSelect &&
                timePickerRef.current &&
                !timePickerRef.current.contains(e.target) &&
                setShowTimeSelect(false);
        };

        document.addEventListener("mousedown", outsideClick);

        return () => document.removeEventListener("mousedown", outsideClick);
    }, [showTimeSelect]);

    return (
        <>
            <div className={`m-b-20 inputWrpr`}>
                <QuestionLabel
                    handleAddHighlight={handleAddHighlight}
                    accessor={question.accessor}
                    allFields={allFields}
                >
                    <label
                        className={`ffmr fs12 ${checkError && !allFields[question.accessor] && question?.isRequired === true
                            ? "error"
                            : null
                            }`}
                    >
                        {question?.order?.label || question.label}
                    </label>
                </QuestionLabel>

                <div className={questionsStyles.timeInputWrapper}>
                    <input
                        readOnly={true}
                        onClick={() => setShowTimeSelect(!showTimeSelect)}
                        className={classNames(`ffml fs16 customInput`, questionsStyles.customInput)}
                        value={time}
                        style={{
                            ...(isHighLightInfo && {
                                border: `1px solid ${isHighLightInfo}`,
                                borderRadius: "4px",
                            }),
                        }}
                        onChange={e => setAllFieldsValue(question.accessor, e.target.value)}

                    />
                    {allFields.transferTime && (
                        <div
                            className={questionsStyles.clearBtn}
                            onClick={() => {
                                setTime("");
                                setAllFieldsValue(question.accessor, null);
                            }}
                        >
                            <HtmlTooltip content={"Clear Time"}>
                                <div>x</div>
                            </HtmlTooltip>
                        </div>
                    )}
                </div>
            </div>
            {showTimeSelect ? (
                <>
                    <div className={styles.overlay} />
                    <div className={styles.timePopup} ref={timePickerRef}>
                        <TimeKeeper
                            // time={allFields[question.accessor] || "12:00pm"}
                            switchToMinuteOnHourSelect
                            onChange={data => {
                                setTime(data.formatted12);
                                return setAllFieldsValue(question.accessor, data.formatted12);
                            }}
                            doneButton={newTime => (
                                <div
                                    style={{
                                        textAlign: "center",
                                        padding: "10px 0",
                                        cursor: "pointer",
                                    }}
                                    onClick={() => {
                                        setTime(newTime.formatted12);
                                        setAllFieldsValue(question.accessor, newTime.formatted12);
                                        setShowTimeSelect(false);
                                    }}
                                >
                                    Done
                                </div>
                            )}
                        />
                    </div>
                </>
            ) : undefined}
        </>
    );
};

const Questions = props => {
    const { checkError = false, isAskAdmissionDate, selectedItem, isOnlyHospitalDashboard } = props;
    const [questions, setQuestions] = useState([]);
    const [expanded, setExpanded] = useState(false);
    //const [allFields, setAllFields] = useState(props.allFields);
    const allFields = useMemo(() => {
        return props.allFields
    }, [props.allFields]);

    const getQuestions = async (propsAllFields) => {
        let transferType = propsAllFields?.transferType;
        if (propsAllFields && (propsAllFields.transferType === "unplannedHospitalTransfer" || propsAllFields.transferType === "plannedHospitalTransfer")) {
            transferType = "hospitalTransfer"
        }
        const res = await getAllQuestions({
            facilityid: localStorage.getItem("facilityId"),
            forType: propsAllFields?.type === "readmission" ? "admission" : propsAllFields?.type,
            ...propsAllFields?.type === "transfer" && { forTransferType: transferType }
        })
        if (res && res.length > 0) {
            setQuestions(res);
            if (props.setQuestions) {
                props.setQuestions(res);
            }
        }
    };

    useEffect(() => {
        //setAllFields(props.allFields)
        getQuestions(props.allFields);
        // eslint-disable-next-line
    }, [props.allFields]);

    const ifDepends = question => {
        const { dependsOn, dependsOnValues, accessor } = question;

        if (!dependsOn) return true;
        if (dependsOn === "server") {
            if (selectedItem || isOnlyHospitalDashboard) {
                return isAskAdmissionDate || allFields[accessor] != null ? true : false;
            } else {
                return isAskAdmissionDate ? true : false;
            }
        } else {
            return dependsOnValues.includes(allFields[dependsOn]);
        }
    };

    const handleClickExpand = useCallback((textValue, accessor) => {
        setExpanded({ value: textValue, accessor });
    }, [setExpanded]);


    const renderQuestion = (question) => {
        switch (question.type) {
            case "text":
                return (
                    <>
                        {ifDepends(question) ? (
                            <TextQuestion
                                question={question}
                                allFields={props.allFields}
                                setAllFieldsValue={props.setAllFieldsValue}
                                checkError={checkError}
                                handleClickExpand={handleClickExpand}
                                handleAddHighlight={props.handleAddHighlight}
                            />
                        ) : undefined}
                    </>
                );
            case "date":
                return (
                    <>
                        {ifDepends(question) ? (
                            <DateQuestion
                                question={question}
                                allFields={props.allFields}
                                setAllFieldsValue={props.setAllFieldsValue}
                                checkError={checkError}
                                handleAddHighlight={props.handleAddHighlight}
                            />
                        ) : undefined}
                    </>
                );
            case "validation":
                return (
                    <>
                        {ifDepends(question) ? (
                            <ValidationQuestion
                                question={question}
                                allFields={props.allFields}
                                selectedProps={props}
                                setAllFieldsValue={props.setAllFieldsValue}
                                checkError={checkError}
                                handleAddHighlight={props.handleAddHighlight}
                            />
                        ) : undefined}
                    </>
                );

            case "toggle":
                return (
                    <>
                        {ifDepends(question) ? (
                            <ToggleQuestion
                                question={question}
                                allFields={props.allFields}
                                setAllFieldsValue={props.setAllFieldsValue}
                                checkError={checkError}
                                handleAddHighlight={props.handleAddHighlight}
                            />
                        ) : undefined}
                    </>
                );

            case "time":
                return (
                    <>
                        {ifDepends(question) ? (
                            <TimeQuestion
                                question={question}
                                allFields={props.allFields}
                                setAllFieldsValue={props.setAllFieldsValue}
                                checkError={checkError}
                                handleAddHighlight={props.handleAddHighlight}
                            />
                        ) : undefined}
                    </>
                );
            default:
                return undefined;
        }

        // return re;
    };

    return (
        <div>
            <div>

                {questions && questions.length > 0 && (
                    <>
                        {questions.map((question, idx) => {
                            return (
                                <React.Fragment key={`idx-${idx}`}>{renderQuestion({ ...question.question, order: question?.order })}</React.Fragment>
                            );
                        })}
                    </>
                )}
            </div>
            {!!expanded && (
                <AddPatientTextPopup
                    title="Details"
                    isConfirm={false}
                    content={expanded?.value}
                    handleClose={() => setExpanded(null)}
                    handleSubmit={(valueData) => {
                        props.setAllFieldsValue(expanded?.accessor, valueData);
                        setExpanded(null);
                    }}
                />
            )}

        </div>
    );
};

export default Questions;
