import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

const DefaultDialog= ({
    title,
    children,
    actions,
    onClose,
    ...dialogProps
}) => {
    return (
        <Dialog
            open={true}
            maxWidth="md"
            onClose={onClose}
            {...dialogProps}
            sx={{
                zIndex : 50000
            }}
        >
            <DialogTitle sx={{ m: 0, p: 2 }}>
                {title}
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>
                {children}
            </DialogContent>
            {actions && (
                <DialogActions >
                    {actions}
                </DialogActions>
            )}
        </Dialog>
    );
};

export default DefaultDialog;
